import React from 'react';

import SEO from 'components/Seo';
import Header from 'components/Header';
import Footer from 'components/Footer';

import LayoutMain from 'components/layout/layoutMain';
import LayoutPanel from 'components/layout/layoutPanel';

import Link from 'components/common/Link';

import BlogCarousel from 'components/cards/BlogCarousel';
import VoucherRedeem from 'components/cards/VoucherRedeem';


// asset images
// https://www.gatsbyjs.com/docs/how-to/images-and-media/importing-assets-into-files/
import imgHero from 'images/fringe-world-festival/hero-fringe-disco.png';
import imgStory1 from 'images/fringe-world-festival/fringe-world-story-1.jpg';
import imgStory2 from 'images/fringe-world-festival/fringe-world-story-2.jpg';
import imgSizzle from 'images/fringe-world-festival/hero-fringe-sizzle.png';
import imgFringeWorldFestivalBadge from 'images/fringe-world-festival/logo-fringe-badge.png';
import 'styles/pages/fringe-world-festival.css';

function FringeWorldFestivalPage() {

	return (
		<>
            <SEO title="FRINGE WORLD Festival | Kleenheat" 
            	 description="Our Major Partnership with FRINGE WORLD helped the event achieve growth across the board compared to 2021 and a great event overall."
        	/>
            <Header />
            <LayoutMain>

            	{/* hero banner */}
            	<LayoutPanel background="mer-bg--primary-cyan"                 
			                 padding="mer-pb-ju"
			                 theme="mer-theme--cyan">
			        <div className="container">
			            <div className="row mer-panel justify-content-center align-items-end">
		                    <div className="col-10 col-sm-8 col-lg-6 mer-text--size-lg">

		                    	<div className="logo-fringe-world">
						          <img src={imgFringeWorldFestivalBadge} 
			                           alt="Fringe World Festival logo" />
						        </div>

								<div className="content">
		                            <h2>FRINGE WORLD, felt good!</h2>
		                            <p>We're proud to wrap up our sponsorship of the <a href="https://fringeworld.com.au?utm_source=kleenheat&utm_medium=referral" target="_blank" title="Open Fringe World Festival site in a new tab">FRINGE WORLD Festival</a> for another year. The Festival was a great success, attracting over 280,000 attendees to 554 shows performed by more than 2,000 artists.</p>
									<p><strong>Kleenheat customers</strong> benefited from discounts, <strong>enjoying $10 off tickets</strong> and receiving some <strong>$25 gift vouchers</strong> to make the most of a night out.</p>
		                        </div>

								{/* <div className="mer-panel__actions mer-button-group">
									<Link text="Ticket offer"
										link="#voucher"
										linkClass="mer-button mer-button--primary" />

									<Link text="Learn more"
										link="#sizzle"
										linkClass="mer-button mer-button--secondary" />
								</div> */}
							</div>
							
							<div className="mer-panel__images col-10 col-sm-8 col-lg-6">
								<img src={imgHero} 
									className="mer-panel__image"
									alt="Ice skate" />
							</div>
			            </div>
					</div>
			    </LayoutPanel>

		    	<LayoutPanel background="mer-bg--gs-grey-lighter"
			                 padding="mer-py-ju"
			                 border="mer-border--bottom"
			                 id="featured">
			        <div className="container">
			            <div className="row mer-panel align-items-center justify-content-center">

			            	<div className="mer-panel__images col-10 col-sm-5 order-sm-1">
								<img src={imgStory1}
										className="mer-panel__image"
										alt="Dancer in a fancy costume" />
							</div>

			              	<div className="col-10 col-md-5 col-sm-8 order-sm-2 offset-md-1">
			              		<div className="content">
				                	<h2 className="mer-typography--headline4"><a href="/blog/community/fringe-world-festival-perth-about" title="Learn more Fringe World Festival">From Edinburgh to Perth</a></h2>
				                	<p>Did you know Perth is home to the 3rd largest Fringe Festival in the world?</p>

				                	<div className="mer-panel__actions mer-button-group">
		                                <Link text="Learn more"
											  linkClass="mer-button mer-button--primary"
				                              link="/blog/community/fringe-world-festival-perth-about" />
		                            </div>
	          					</div>
			              	</div>
			            </div>
			        </div>
			    </LayoutPanel>

				<BlogCarousel id="stories"
							title="Be inspired by WA’s homegrown talent"
							titleCol="col-10"
							// apiQuery="?tag=moving-house&posts_per_page=6"
							apiQuery="?tag=fringe"
				/>
		    </LayoutMain>
			<Footer />
		</>
	)
}

export default FringeWorldFestivalPage;