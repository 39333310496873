import React, { useState } from 'react';

import LayoutPanel from 'components/layout/layoutPanel';
import Image from 'components/common/Image';

import VoucherRedeemInput from 'components/common/VoucherRedeemInput'

function VoucherRedeem (props) {

    const [isFormSubmitted, setIsFormSubmitted] = useState(false);

    function handleClick(e) {
        e.preventDefault();
        window.location.hash = props.id;
        window.location.reload();
    }

    return (
        <LayoutPanel padding="mer-py-lg"
                     background="mer-bg--gs-grey-lighter"
                     id="ticket"
                     {...props}>
            <div className="container">
                <div className="row mer-panel flex-sm-row-reverse">

                    <div className={`col-10 col-sm-6 offset-sm-1`}>

                        {/* not submitted */}
                        <div className={isFormSubmitted ? 'd-none' : 'content mer-text--size-lg'}>
                            <h2>$10 FRINGE WORLD gift voucher for Kleenheat customers</h2>
                            <p>Enter the email address linked to your Kleenheat account, and we’ll email you a unique $10 gift voucher code to use on any FRINGE WORLD show ticket purchase*.</p>
                            <p><small><a href='https://fringeworld.com.au/terms-conditions?utm_source=kleenheat&utm_medium=referral' title='Open terms and conditions in a new tab' target='_blank'>*Terms and conditions apply.</a></small></p>

                            <form className="mb-0">
                                <fieldset>
                                    <div className="row">
                                        <div className={`col-12 col-lg-10`}>
                                            <VoucherRedeemInput searchButtonText="Redeem"
                                                                isFormSubmitted={setIsFormSubmitted} />
                                        </div>
                                    </div>
                                </fieldset>
                            </form>
                        </div>

                        {/* submitted */}
                        <div className={`${!isFormSubmitted ? 'd-none' : 'content mer-text--size-lg'}`}>
                            <h4>Thanks for entering your email address!</h4>
                            <p>If the email you provided matches an existing account, we’ve emailed you your unique $10 FRINGE WORLD voucher.</p>
                            <p><strong>Check your email for the next steps and enjoy!</strong></p>
                            <p>If you didn’t receive the voucher, please contact our Customer Service Team on <a href="tel:132180" title="Contact our Customer Service Team">13 21 80</a>, or <a onClick={handleClick} title="Try with another email address">try again with another email address</a>.</p>
                        </div>
                    </div>

                    <div className="mer-panel__images col-5 col-sm-3 mb-0">
                        <Image className="mer-panel__image"
                               src={`/icons/flowmoji/flowmoji-tickets.svg`}
                               alt="Flowmoji ticket" />
                    </div>
                </div>
            </div>
        </LayoutPanel>
    )
}

export default VoucherRedeem;