import React, { useState } from 'react';
import Axios from 'axios';
import PropTypes from 'prop-types';
import { GtmHelper, Svg } from 'kh-common-components';

const VoucherRedeemInput = ({searchButtonText, label, padding, showLabel, isRequired, isFormSubmitted}) => {

    const [emailAddress, setEmailAddress] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const isRequiredField = isRequired ? 'mer-form-label-required' : '';

    let errorClass = errorMessage !== null && errorMessage !== undefined ? 'mer-form__field--status-critical' : '';

    const validateEmail = (email) => {
        let isValid = false;

        const emailRegEx = /(?:[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[A-Za-z0-9-]*[A-Za-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

        if (emailRegEx.test(email)) {
            isValid = true;
        }

        return isValid;
    }

    const handleChange = (e) => {
        e.preventDefault();
        setErrorMessage(null);

        const value = e.target.value;
        setEmailAddress(value);
    }

    const sendEmail = async (email) => {

        try {
            const resultData = await Axios(`/apis/felix/api/fringeworld/redeem?email=${email}`);
        } catch (error) {
            console.log('Error submitting email address');
        }
    };

    const submit = (email) => {
        isFormSubmitted(true);
        sendEmail(email);
        GtmHelper.sendFormComplete('Fringe World Redemption');
    }

    const handleClick = (e) => {
        e.preventDefault();

        if (!emailAddress) {
            setErrorMessage('Email address is required');
        } else if (!validateEmail(emailAddress) && emailAddress) {
            setErrorMessage('Email address is invalid');
        } else {
            setErrorMessage(null);
            submit(emailAddress);
        }
    }

  return (
        
    <div className={`mer-form__field ${errorClass} ${padding}`}>
        { label !== '' &&
          <label className={`mer-form__field-label ${isRequiredField}`} htmlFor="voucherRedeem.email">
              {label}
          </label>
        }
        <div className="mer-form__field-action mer-form__field-action--flex">

            <div className="mdc-text-field mdc-text-field--outlined mdc-text-field--no-label">
                <input 
                    id="voucherRedeem.email"
                    type="email"
                    className="mdc-text-field__input"
                    placeholder="Enter your email address"
                    name="voucherRedeem.email"
                    onChange={handleChange}
                    required
                />
                <div className="mdc-notched-outline">
                    <div className="mdc-notched-outline__leading"></div>
                    <div className="mdc-notched-outline__trailing"></div>
                </div>
            </div>

            { searchButtonText !== undefined && 
                <button onClick={handleClick} className="mer-button mer-button--primary">
                  <span className="mer-text--no-wrap d-sm-block d-none">{searchButtonText}</span>
                  <Svg title={searchButtonText}
                        col="mer-svg-container--size-xs d-sm-none mer-fill--gs-white"
                        icon="chevron-right" />
                </button>             
            }    
        </div>

        { errorMessage !== null && errorMessage !== undefined &&
            <span className="mer-form-field__status-descriptor d-inline-flex px-1">{errorMessage}</span>
        }
    </div>    
  )
}

VoucherRedeemInput.defaultProps = {      
  label: '',
  padding: '',
  sendData: '',
  isRequired: false
};

VoucherRedeemInput.propTypes = {
  searchButtonText: PropTypes.string,
  label: PropTypes.string,
  padding: PropTypes.string,
  sendData: PropTypes.string,
  isRequired: PropTypes.bool
};


export default VoucherRedeemInput;